<template>
    <table>
        <tr>
            <th>Expire Date</th>
            <th>Len</th>
            <th>R</th>
            <th>Option Initial</th>
            <th>Option Current</th>
            <th>PL</th>
            <th>PL %</th>
        </tr>
        <tr class="sa-pick-table-row" v-for="(option,index) in options"  :key="index">
            <td>{{option.expirationDate}}</td>
            <td>{{getLengthExpire(option)}}</td>
            <td>{{getDaysTilExpire(option)}}</td>
            <td>{{toUSD((option.bid + option.ask)/2)}}</td>
            <td>{{toUSD(getLastPrice(option))}}</td>
            <td :style="getPLStyle(option)">{{ toUSD(getPL(option)*100) }}</td>
            <td :style="getPLStyle(option)">{{ toPercent(getPL(option)/((option.bid + option.ask)/2)) }}</td>
        </tr>       
    </table>
</template>
<script>
import Globals from '../utils/globals';

export default {
    props: ['stockoptions','buydate'],
    data() {
        return {
            options: []
        }
    },
    methods: {
        setOptions() {
            this.options = this.stockoptions.filter(option => {return new Date() < new Date(option.expirationDate)})
        },
        toUSD(value) {
            return Globals.toUSD(value)
        },
        toPercent(value) {
            return Globals.toPercent(value)
        },
        getPL(option) {
            return this.getLastPrice(option)-((option.bid + option.ask)/2)
        },
        getPLStyle(option) {
            return { color: this.getPL(option) >=0 ? 'green' : 'red'};
        },
        getStockPLStyle() {
            return  {color: this.getLastStockPrice() > this.stockdata.price ? 'green' : 'red'};
        },
        getLastPrice(option) {
            const SavedOptions = option.savedOptions
            return (SavedOptions[SavedOptions.length - 1].bid + SavedOptions[SavedOptions.length - 1].ask)/2
        },
        getLengthExpire(option) {
            const dateExp =   new Date(option.expirationDate);
            const dateBuy = new Date(this.buydate);
            const dateDif = dateExp.getTime() - dateBuy.getTime()
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.ceil(dateDif/dayLength)
        },
        getDaysTilExpire(item) {
            const dateExp =   new Date(item.expirationDate);
            const dateNow = new Date();
            const dateDif = dateExp.getTime() - dateNow.getTime()
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.ceil(dateDif/dayLength)
        },
    },
    mounted() {
        this.setOptions()
    }
}
</script>

<style lang="scss" scoped>
td, th {
    border: 1px solid gray;
    padding-right: 5px;
    text-align: right;
}
table {
    width: 97%;
    margin: 5px;
    max-width: 660px;
}
</style>