<template>
    <ion-content>       
      <div class="sa-main-container">
        <input class="sa-stock-input" type="text" placeholder="Stock Ticker to Add" v-model="stockTicker" @input="onChange">
      </div>
      <div style="text-align:center"> 
          <div class="sa-button-item" @click="addStock('call')" expand="block">Add Stock - Buy</div>
          <div class="sa-button-item sa-sell" @click="addStock('put')" expand="block">Add Stock - Sell</div>
      </div>
      <div class="sa-stock-success" v-if="showSuccess">Stock Has Been Successfully Added</div>
      <div class="sa-stock-fail" v-if="showError">There are NO OPTIONS available for this Stock</div>
    </ion-content>
</template>

<script>
import { IonContent } from '@ionic/vue';
import Globals from '../utils/globals';
export default {
    components: {
        IonContent,
    },
    data() {
        return {
            stockTicker: '',
            showSuccess: false,
            showError: false,
            buyType: 'call'
        }        
    },
    methods: {
        onChange(evt) {
            this.stockTicker = evt.target.value.toUpperCase();
        },
        addStock(type) {
            this.buyType = type
            window.scrollTo(0, 0);
            this.expirations = [];
            this.pickOptions = [];
            this.$store.dispatch("getStockPrice", { ticker: this.stockTicker })
                .then((response) => {
                this.close = Number(response);
                this.$store
                    .dispatch("getOptionDates", { ticker: this.stockTicker })
                    .then((response) => {
                    console.log('ENTER RESPONSE', response)
                    if (response.expirations) {
                        response.expirations.expiration.forEach((item) => {
                        if (Globals.dateDiffDaysToday(new Date(item.date)) > 30) {
                            this.expirations.push(item.date);
                        }
                        });
                        this.getBestOption(0);
                    } else {
                        this.showError = true;
                    }
                    });
                });
    },
    getBestOption(num) {
      if (num < this.expirations.length) {
        const data = {
          ticker: this.stockTicker,
          date: this.expirations[num],
          type: this.buyType
        };
        this.$store.dispatch("getOptionsInfo", data).then((response) => {
          console.log("getOptionsInfo", response);
          if (response) {
            const optionData = {
              bid: response.bid,
              ask: response.ask,
              last: response.last,
              strike: response.strike,
              volume: response.volume,
              interest: response.open_interest,
              expirationDate: response.expiration_date,
            };
            this.pickOptions.push(optionData);
          }
          let nextNum = num + 1;
          this.getBestOption(nextNum);
        });
      } else {
        this.savePick();
      }
    },
    savePick() {
      const data = {
        ticker: this.stockTicker,
        price: this.close,
        options: this.pickOptions,
        active: true,
        type: this.buyType,
        dateentered: Globals.setDateFormat(new Date())
      };
      this.$store.dispatch("savePick", data).then((response) => {
        console.log("PICK SAVED", response);
        if (response.data.result == "SUCCESS") {
          this.$emit('add-stock',this.stockTicker)
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
            this.stockTicker = ''
          },4000);
        }
      });
    },
    }
}
</script>

<style lang="scss" scoped>
ion-content {
  text-align: center;
}
.sa-main-container {
  margin: auto;
  margin-top: 20px;
}
.sa-button-item {
  background-color: #3c6577;
  padding: 10px 15px;
  max-width: 150px;
  color: white;
}
.sa-stock-input {
  padding: 10px;
  text-align: center;
}
.sa-stock-success {
  padding: 10px;
  color: green;
  font-weight: 600;
}
.sa-stock-fail {
  padding: 10px;
  color: red;
  font-weight: 600;
}
.sa-sell {
  background-color: #32a620;
}

</style>