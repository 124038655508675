<template>
    <ion-content>
        <div class="sa-filter-container">        
        <ion-item>
            <ion-label class="sa-input-label">Purchase On/After</ion-label>
            <ion-input type="date" placeholder="Purchased On/After" v-model="filterValues.purchaseDate"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Option Expiration Date</ion-label>
            <ion-input type="date" placeholder="Option Date" v-model="filterValues.optionDate"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label" >Purchase Price Below</ion-label>
            <ion-input type="number" placeholder="Price Below" v-model="filterValues.purchaseBelow"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Days Left Less Than</ion-label>
            <ion-input type="number" placeholder="Price Below" v-model="filterValues.daysLeft"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Maximum Days Since Purchase</ion-label>
            <ion-input type="number" placeholder="Maximum Days Since Purchase" v-model="filterValues.daysPurchase"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Minimum Expiration Length</ion-label>
            <ion-input type="number" placeholder="Minimum Expiration Length" v-model="filterValues.minExpireLen"></ion-input>
            
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Use Minimum Expiration</ion-label>
            <CheckBox
              v-bind:checkBoxLabel="''"
              v-on:item-selected="filterValues.isMinExpire = $event"
              :cbmodel="filterValues.isMinExpire"
            />
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">Puts Only</ion-label>
            <CheckBox
              v-bind:checkBoxLabel="''"
              v-on:item-selected="filterValues.putsOnly = $event"
              :cbmodel="filterValues.putsOnly"
            />
        </ion-item>
        <ion-item>
            <ion-label class="sa-input-label">No Puts</ion-label>
            <CheckBox
              v-bind:checkBoxLabel="''"
              v-on:item-selected="filterValues.noPuts = $event"
              :cbmodel="filterValues.noPuts"
            />
        </ion-item>
        <div style="text-align:center"> 
            <ion-button class="sa-button-item" @click="updateFilters" expand="block">Update</ion-button>
        </div>
        </div>
    </ion-content>
</template>

<script>
import { IonContent, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import CheckBox from './Utilities/CheckBox.vue';

export default {
    props: ['filters'],
    components: {
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonButton,
        CheckBox,
    },
    data() {
        return {
            filterValues: {...this.filters}
        }        
    },
    methods: {
        updateFilters() {
            console.log('UPDATE')
            this.$emit('update-filters',this.filterValues)
        }
    }
}
</script>

<style lang="scss" scoped>
ion-button {
    max-width: 300px;
}
ion-input {
    font-weight:600;
    background-color: #f0f8ff;
    margin: 6px;
    --padding-start: 10px;
    border: 1px solid lightgrey;
}
.sa-filter-container {
    max-width: 400px;
    margin: auto;
}

</style>