<template>
<div>
    <div class="dropdown-button" @click="buttonClick">{{setShowHide()}} STOCK CHART </div>
    <div class="dropdown-component">
        <div
        class="dropdown-selected-container"
        :style="dropstyle"
        @click.stop="checkSelection"
        >
        {{ getSelected }}
        <i
            class="fas fa-caret-down"
            @click.stop="checkSelection"
            v-show="showCaret"
        ></i>
        </div>
        <div class="dropdown-selection-container" v-show="showSelection">
        <div
            v-for="(item, index) in optioncontent"
            v-bind:key="index"
            class="dropdown-item"
            @click="setSelected(item)"
        >
            {{ item.name }}
        </div>
        </div>
        <!-- USE EXAMPLE -->
        <!-- <Dropdown 
            v-on:item-selected="selectedProvider = $event" 
            v-bind:selected="selectedProvider.name" 
            v-bind:optioncontent="allStaff"
            :placeholder="bookingProvider">
        </Dropdown> -->
    </div>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
      showSelection: false,
      activeItem: "",
      showCaret: true,
      selectedValue: 6,
      showHide: true,

    };
  },
  props: ["selected", "placeholder", "optioncontent", "dropstyle", "caretshow"],
  methods: {
    buttonClick() {
        console.log('BUTTON CLICKED',this.activeItem,'SEL', this.selectedValue);        
        this.$emit("item-selected", {value: this.selectedValue,show:this.showHide});
        this.showHide = !this.showHide
        
        
    },
    setShowHide() {
        return this.showHide ? 'SHOW' : 'HIDE';
    },
    setSelected(item) {
      this.selectedValue = item.value
      this.activeItem = item.name;
      this.showSelection = false;
      document.removeEventListener("click", this.handleOutsideClick);

      window.console.log("CARET SHOW", this.caretshow, item, this.activeItem);
      this.$emit("item-selected", {value: this.selectedValue,show:!this.showHide});
      this.showCaret = this.caretshow ? true : false;
    },
    checkSelection() {
      window.console.log("CLICKED ON DROP SELECTION", this.optioncontent);
      if (this.showSelection) {
        this.showSelection = false;
      } else {
        this.showSelection = true;
        document.addEventListener("click", this.handleOutsideClick);
      }
    },
    removeOutsideClick() {
      window.console.log("REMOVE OUTSIDE CLICK");
      document.removeEventListener("click", this.handleOutsideClick);
    },
    handleOutsideClick() {
      window.console.log("Handle Outside Click", this.showSelection);
      this.removeOutsideClick();
      if (this.showSelection) {
        this.showSelection = false;
      }
    },
  },
  computed: {
    getSelected() {
      if (this.activeItem) {
        return this.activeItem;
      }
      if (this.placeholder.length > 1) {
        return this.placeholder;
      }
      return "";
    },
  },
  watch: {
    // selected: function() {
    //     this.showSelection = false;
    //     this.activeItem = this.selected;
    // }
  },
};
</script>
<style lang="scss" scoped>
.dropdown-component {
    display: inline-block;
    position: relative;
    top: 10px; 
}
.dropdown-selected-container {
  height: 39px;
  border: 1px solid #476b7b;
  margin-left: 0;
  min-width: 25px;
  background-color: white;
  font-weight: 500;
  color: #0c3447;
  line-height: 15px;
  cursor: pointer;
  font-size: 14px;
  padding: 12px;
}
.dropdown-selection-container {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  min-width: 100px;
  left: 5px;
  top: 26px;
  padding: 0 5px;
  margin-right: 5px;
  z-index: 5;
  width: 150px;
  font-size: 13px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 150px;
}
.dropdown-item {
  border-bottom: 1px solid lightgray;
  padding: 10px 5px;
  width: 100%;
  cursor: pointer;
  background-color: white;
  color: gray;
  font-size: 13px;
}
.selection-text {
  cursor: pointer;
}
.dropdown-button {
    padding: 10px;
    background-color: #238874;
    color: white;
    max-width: 230px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
    display: inline-block;
}
</style>
