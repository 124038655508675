<template>
    <ion-page>
        <main-body-component title="Log-in">
            <sign-in-form @submit-login="userLogin" @register-user="registerUser"></sign-in-form>
        </main-body-component>
        
    </ion-page>
</template>

<script>
import MainBodyComponent from '../components/MainBodyComponent.vue'
import SignInForm from '../components/SignInForm.vue'
import { IonPage } from '@ionic/vue'
export default {
    components: {
        MainBodyComponent,
        SignInForm,
        IonPage,
    },
    methods: {
        userLogin(data) {
            console.log('LOGIN DATA',data)
            this.$router.replace('/home/')
        },
        registerUser() {
            this.$router.push('/registration/')
        }
    }
}
</script>