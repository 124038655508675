<template>
    <div class="sa-table-container">
        <table>
            <tr>
                <th>Stock</th>
                <th>L</th>
                <th>Ex</th>
                <th class="amedia-med">S</th>
                <th>Cost</th>
                <th>Value</th>
                <th>PL</th>                
                <th>Yst PL</th>
                <th class="amedia-med">Yst %</th>
                <th class="amedia-med">% TOT</th>
            </tr>
            <tr>
                <td>{{picklist.length}}</td>
                <td></td>
                <td></td>
                <td class="amedia-med"></td>
                <td>{{toUSD(totalValues.totalCost)}}</td>
                <td>{{totalValues.totalValue}}</td>
                <td :class="{'sa-gain': totalValues.totalValue - totalValues.totalCost > 0, 'sa-loss': totalValues.totalValue - totalValues.totalCost < 0}">{{(totalValues.totalValue - totalValues.totalCost).toFixed(2)}}</td>
                <td  class="amedia-med" :class="{'sa-gain': getYestTotalPL().yestTot > 0,'sa-loss': getYestTotalPL().yestTot < 0 }" >{{toUSD(getYestTotalPL().yestTot*100)}}</td>
                <td  class="amedia-med" :class="{'sa-gain': getYestTotalPL().yestTot > 0,'sa-loss': getYestTotalPL().yestTot < 0 }">{{toPercent(getYestTotalPL().yestPer)}}</td>
                <td class="amedia-med-hide"
                :class="{'sa-gain': (((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100) > 0, 'sa-loss': (((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100) < 0}">
                    {{(((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100).toFixed(1)}}%
                </td>
                <td class="amedia-med"
                :class="{'sa-gain': (((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100) > 0, 'sa-loss': (((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100) < 0}">
                    {{(((Number(totalValues.totalValue)/Number(totalValues.totalCost))*100) -100).toFixed(1)}}%
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="amedia-med"></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="amedia-med"></td>
                <td class="amedia-med"></td>
                <td class="amedia-med"></td>
            </tr>
            <tr class="sa-pick-table-row" v-for="(item,index) in picklist"  :key="index">
                <td class="sa-pick-table-item selectable"
                    :class="{'put-item': item.buytype === 'put'}" 
                    @click="showInfo(item.id)"><b>{{item.ticker}}</b></td>
                <td>{{getDaysSinceBought(item)}}</td>
                <td>{{getDaysTilExpire(item)}}</td>
                <td class="amedia-med">{{getSector(item)}}</td> 
                <td class="sa-pick-table-item">{{(item.initialOptionPrice * 100).toFixed(2)}}</td>
                <td class="sa-pick-table-item">{{(item.lastOptionPrice * 100).toFixed(2)}}</td>
                <td class="sa-pick-table-item" 
                    :class="{'sa-gain': (item.lastOptionPrice - item.initialOptionPrice) > 0, 'sa-loss':  (item.lastOptionPrice - item.initialOptionPrice) < 0}">
                        {{((item.lastOptionPrice - item.initialOptionPrice) * 100).toFixed(2)}}
                </td>
                
                <td class="sa-pick-table-item" 
                    :class="{'sa-gain': getLastChg(item).lastChg > 0, 'sa-loss': getLastChg(item).lastChg < 0}">
                        {{(getLastChg(item).lastChg * 100).toFixed(2)}}
                </td>
                <td class="sa-pick-table-item amedia-med" 
                    :class="{'sa-gain': getLastChg(item).lastChg > 0, 'sa-loss': getLastChg(item).lastChg < 0}">
                        {{toPercent(getLastChg(item).lastChgPercent)}}
                </td>
                <td class="amedia-med" :class="{'sa-gain': getTotalPL(item) >= 0, 'sa-loss': getTotalPL(item) < 0}">{{toPercent(getTotalPL(item))}}</td>
            </tr>
        </table>
    </div> 
</template>

<script>
import Globals from '../utils/globals';
import Constants from '../utils/constants';

export default {
    props: ['picklist','totalValues'],
    components: {
       
    },
    data() {
        return {
            totalCost: 0,
            totalValue: 0,
        }
    },
    methods: {
        getYestTotalPL() {
            let yestTot = 0;
            this.picklist.forEach(item => {
                yestTot += this.getLastChg(item).lastChg;
            })           
            return {
                yestTot: yestTot,
                yestPer: (yestTot/this.totalValues.totalCost)*100,
            }
        },
        getTotalPL(item) {
            const PL = (item.lastOptionPrice - item.initialOptionPrice )
            const PerPL = PL/item.initialOptionPrice
            return PerPL
        },
        getLastChg(item) {
            return {
                lastChg: item.lastOptionPrice-item.prevOptionPrice,
                lastChgPercent: (item.lastOptionPrice-item.prevOptionPrice)/item.initialOptionPrice
            }
        },
        showInfo(id) {
            this.$emit('stock-selected',id)
        },
        getDaysTilExpire(item) {
            const dateExp =   new Date(item.bestExpireDate);
            const dateNow = new Date();
            const dateDif = dateExp.getTime() - dateNow.getTime()
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.ceil(dateDif/dayLength)
        },
        getDaysSinceBought(item) {
            const dateBought =   new Date(item.date);
            const dateNow = new Date();
            const dateDif = dateNow.getTime() - dateBought.getTime();
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.floor(dateDif/dayLength)
        },
        getTotalCost() {           
            this.picklist.forEach(item => {
                this.totalCost += item.initialOptionPrice;
                this.totalValue += item.lastOptionPrice;
            })
            this.totalCost = (this.totalCost * 100).toFixed(2);
            this.totalValue = (this.totalValue * 100).toFixed(2);
            const totalValues = {
                totalCost: this.totalCost,
                totalValue: this.totalValue
            }
            this.$store.commit('SET_TOTALVALUES', totalValues)
        },
        getSector(stock) {
            if (stock.sector) {
                return Constants.sectors[stock.sector]
            }
        },
        toUSD(value) {
            return Globals.toUSD(value)
        },
        toPercent(value) {
            return Globals.toPercent(value)
        },
    },
    watch: {
        picklist: {            
            handler() {
                this.getTotalCost();
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.pick-item {
    height: 60px;
}
.sa-pick-row {
  border: 1px solid black;
  border-bottom: none;
  border-top: 2px solid black;
  height: 16px;
  display: inline-block;
  line-height: 25px;
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.sa-pick-item {
  display: inline-block;
  border-right: 1px solid rgb(102, 85, 85);
  height: 25px;
  padding: 0 10px;
  width: 55px;
  text-align: right;
  vertical-align: top;
}
.med-width {
  width: 90px;
}
td, th {
    border: 1px solid gray;
    padding-right: 5px;
    text-align: right;
}
table {
    width: 97%;
    margin: 5px;
}
.sa-gain {
    background-color: green;
    color: white
}
.sa-loss { 
    background-color: red;
    color: white;
}
.sa-table-container {
    height: 100%;
    overflow-y: auto;
}
.amedia-med {
    display: none;
}
.amedia-med-hide {
    display: table-cell;
}
.put-item {
    color: #9e1e1e;
}

@media screen and (min-width: 680px) {
    .amedia-med {
        display: table-cell;
    }
    .amedia-med-hide {
        display: none;
    }
    
}
</style>