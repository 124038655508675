// import Vue from "vue";
import { createStore} from "vuex";
import axios from "axios";
import Constants from "../utils/constants";

axios.defaults.headers.common["Authorization"] =
  "Bearer DKe97e74iUyU9g0J1DlG3AMJ0rHw";

const store = createStore({
    state() {
        return {
            Authorization: "Bearer DKe97e74iUyU9g0J1DlG3AMJ0rHw",
            headers: {
                Authorization: "Bearer DKe97e74iUyU9g0J1DlG3AMJ0rHw",
                Accept: "application/json",
            },
            picklist: [],
            selectlist:[],
            totalvalues:{}
        }
    },
    actions: {
        getPickList({commit}) {
            const url = `${Constants.nodeURL}picks`;
            return axios({
            method: "get",
            url: url,
            })
            .then(function (response) {
                commit('SET_PICKLIST',response.data.data.rows);
                return response.data;
            })
            .catch(function (error) {
                console.log("PICK LIST ERROR", error);
            });
        },
        login(context,data) {
            console.log('LOGIN DATA',data)
            return 'SUCCESS'
        },
        getCompanyInfo({dispatch},data) {
            const ticker = data.ticker;
            const url = `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${ticker}&apikey=GF1YB2QPSR3H92Z5`
            console.log('URL',url)
            return axios({
                method:'get',
                url: url
            }).then(response=> {
                console.log('COMPANY INFO',response.data,response.data.Sector)
                if (!response.data.Sector) {
                    data.company.Sector='Unknown'
                } else {
                    data.company = response.data;
                }
                
                dispatch('updatePickListCompany',data)
            })
        },
        getCompanyBalanceSheet(context,data) {
            const ticker = data.ticker;
            const url = `https://www.alphavantage.co/query?function=BALANCE_SHEET&symbol=${ticker}&apikey=GF1YB2QPSR3H92Z5`;
            console.log('URL',url)
            return axios({
                method:'get',
                url: url
            }).then(response=> {
                console.log('COMPANY BAL SHEET',response.data)
                if (!response.data.Sector) {
                    data.company.Sector='Unknown'
                } else {
                    data.company = response.data;
                }
            })
        },
        getCompanyIncomeStatement(context,data) {
            const ticker = data.ticker;
            const url =  `https://www.alphavantage.co/query?function=INCOME_STATEMENT&symbol=${ticker}&apikey=GF1YB2QPSR3H92Z5`;
            console.log('URL',url)
            return axios({
                method:'get',
                url: url
            }).then(response=> {
                console.log('COMPANY INCOME STATE',response.data)
                if (!response.data.Sector) {
                    data.company.Sector='Unknown'
                } else {
                    data.company = response.data;
                }
            })
        },
        getCompanyFinancialInfo(context,data) {
          const url = `https://api.polygon.io/vX/reference/financials?ticker=${data.ticker}&apiKey=${Constants.polygonToken}`
          console.log('URL',url)
            return axios({
                method:'get',
                url: url
            }).then(response=> {
                console.log('COMPANY INCOME STATE',response.data)
                if (!response.data.Sector) {
                    data.company.Sector='Unknown'
                } else {
                    data.company = response.data;
                }
            })
        },
        getNewStockInfo(context,data) {
          const options = {
            method: 'GET',
            url: `${Constants.rapidAPIURL}${data.link}`,
            params: {symbol: data.ticker, region: 'US'},
            headers: {
              'X-RapidAPI-Host': Constants.rapidAPIHost,
              'X-RapidAPI-Key': Constants.rapidAPIToken,
            }
          };
          
          return axios.request(options).then(response => {
              console.log('COMPANY INFO RESPONSE',response.data);
              return response.data            
          }).catch(function (error) {
            console.error(error);
          });
        },
        
        updatePickListCompany(context, data) {
            console.log('got data',data)
            const url = `${Constants.nodeURL}updatecompany`;
            return axios({
              method: "post",
              url: url,
              data: data,
            })
              .then(function (response) {
                console.log("COMPANY UPDATED RESULT", data,'RES DATA', response.data.company);
                return response.data;
              })
              .catch(function (error) {
                console.log("COMPANY UPDATED ERROR", error);
              });
        },
        getStockInfo(context, data) {
            console.log("GET STOCK INFO", data);
            const url = `${Constants.tradierURL}markets/history?symbol=${data.stock}&interval=daily&start=${data.start}&end=${data.end}`;
            console.log("INFO", data, url);
            return axios({
                method: "get",
                url: url,
            })
                .then(function (response) {
                    console.log("GOT STOCK DATA", response);
                    return response.data;
                })
                .catch(function (error) {
                    window.console.log(error);
                });
        },
        getStockStatistics(context, data) {
            const url = `${Constants.tradierURL}markets/quotes?symbols=${data.stock}&greeks=false`;
            return axios({
              method: "get",
              url: url,
            })
              .then(function (response) {
                return response.data.quotes.quote;
              })
              .catch(function (error) {
                window.console.log(error);
              });
        },
        savePick(context, data) {
            const url = `${Constants.nodeURL}savepick`;
            return axios({
              method: "post",
              url: url,
              data: data,
            })
              .then(function (response) {
                console.log("SAVE PICK RESULT", data, response.data);
                return response;
              })
              .catch(function (error) {
                console.log("SAVE PICK ERROR", error);
              });
        },
        getStockPrice(context, data) {
            const ticker = data.ticker;
            const url = `${Constants.tradierURL}markets/quotes?symbols=${ticker}&greeks=false`;
            return axios({
              method: "get",
              url: url,
            })
              .then(function (response) {
                return response.data.quotes.quote.last;
              })
              .catch(function (error) {
                console.log("GET STOCK QUOTE ERROR", error);
              });
        },
        getOptionDates(context, data) {
            const ticker = data.ticker;
            const url = `${Constants.tradierURL}markets/options/expirations?symbol=${ticker}&includeAllRoots=true&strikes=true`;
            return axios({
              method: "get",
              url: url,
            })
              .then(function (response) {
                console.log("OPTION DATES RESULT", response.data);
                return response.data;
              })
              .catch(function (error) {
                console.log("OPTION DATES ERROR", error);
              });
        },
        getOptionsInfo(context, data) {
            // const url = "https://eodhistoricaldata.com/api/options/AAPL.US?api_token=OeAFFmMliFG5orCUuwAKQ8l4WWFQ67YX"
            // https://eodhistoricaldata.com/api/options/AAPL.US?api_token=60cf40d8c57d44.60454536
            // const url =
            // "https://sandbox.tradier.com/v1/markets/options/chains?symbol=AAPL&expiration=2021-06-25&greeks=true";
            const url = `${Constants.tradierURL}markets/options/chains?symbol=${data.ticker}&expiration=${data.date}&greeks=true`;
            return axios({
              method: "get",
              url: url,
            })
              .then(function (response) {
                const options = response.data.options.option;
                console.log('OPTIONS',data,'OPTIONS LIST', options)
                for (let i = options.length - 1; i > -1; i--) {
                  if (
                    data.type == 'put' &&
                    options[i].greeks.delta > -0.16  &&
                    options[i].option_type === 'put'
                     ) {
                      console.log('PUT MATCH',options[i])
                      return options[i];
                      
                  } else if (
                    data.type == 'call' &&
                    options[i].greeks.delta > 0.84  &&
                    options[i].option_type === data.type
                  ) {
                    console.log('CALL MATCH',options[i])
                    return options[i];
                  }
                }
              })
              .catch(function (error) {
                window.console.log(error);
              });
        },
        getStatistics(context, data) {
          const options = {
            method: 'GET',
            url: `https://yh-finance.p.rapidapi.com/stock/v2/${data.type}`,
            params: {symbol: data.ticker},
            headers: {
              'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com',
              'X-RapidAPI-Key': 'db058ed9c2msh48d2d36f4dd3359p1cea14jsnd9c6dcf1ed26'
            }
          };
          return axios.request(options).then(function (response) {
            console.log('GET RESPONSE',data.type, options.url, response.data);
          }).catch(function (error) {
            console.error(error);
          });
          
        }
    },
    mutations:  {
        SET_PICKLIST: (state,list) => {
            state.picklist = list;
        },
        SET_SELECTLIST: (state,list) => {
            state.selectlist = list;
        },
        SET_TOTALVALUES: (state,values) => {
            state.totalvalues = values;
        }
    },
    getters: {
        picklist(state) {
            return state.picklist;
        },
        selectlist(state) {
            return state.selectlist;
        },
        totalvalues(state) {
            return state.totalvalues;
        }
    },
    modules: {},
});

export default store;
