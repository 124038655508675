<template>
    <div class="sa-page-container">
        <div class="sa-topic-item"><b>Name:</b> {{stockdata.company?.Name}}</div>
        <div class="sa-topic-item"><b>Ticker:</b> {{stockdata.ticker}}</div>
        <div class="sa-info-button" @click="showFincInfo=!showFincInfo">{{getFinancialButtonLabel()}}</div>
        <financial-info-component :financialInfo="financialInfo" v-if="showFincInfo"></financial-info-component>
        <div class="sa-topic-item"><b>Initial Stock Price:</b> {{toUSD(this.stockdata.price)}}</div>
        <div class="sa-topic-item">
            <b>Current Stock Price:</b> {{toUSD(this.getLastStockPrice())}}  
            <span :style="getStockPLStyle()">{{toPercent((this.getLastStockPrice() -this.stockdata.price)/this.stockdata.price)}}</span>
        </div>
        <div class="sa-topic-item">
            <b>Stock Profit/Loss: </b> 
            <span :style="getStockPLStyle()">{{toUSD(this.getLastStockPrice() - this.stockdata.price)}}</span>
        </div>
        <dropdown-button-component 
            v-bind:stockTicker="stockdata.ticker" 
            v-bind:buyDate="selectedOptionDate" 
            v-on:item-selected="selectedTimeframe=$event.value,showStockChart=$event.show" 
            v-bind:selected="'none'" 
            v-bind:optioncontent="utils.historyOptions"
            :placeholder="utils.historyOptions[0].name">></dropdown-button-component>
        <stock-chart-component
            v-if="showStockChart"
            v-bind:ticker="stockdata.ticker"
            v-bind:buydate="stockdata.date"
            v-bind:stockhistorylength="selectedTimeframe">
        </stock-chart-component>
        <div class="sa-topic-item">
            <b>Expiration Date:</b> {{this.stockdata.bestExpireDate}}  
        </div>
        <div class="sa-info-button" @click="showExpirations=!showExpirations">{{getExpirationsButtonLabel()}}</div>
        <stock-expirations v-if="showExpirations" v-bind:stockoptions="stockdata.options" v-bind:buydate="stockdata.date"></stock-expirations>
        
        <div class="sa-topic-item">
            <b>Option Type:</b> {{stockdata.buytype?.toUpperCase()}}  
        </div>
        <div class="sa-topic-item">
            <b>Initial Option Price:</b> {{toUSD(getOptionPrice().initialPrice)}} 
            <span> | <b>{{toPercent(getOptionPrice().initialPrice/this.stockdata.price)}}</b></span> 
        </div>
        <div class="sa-topic-item">
            <b>Current Option Price:</b> {{toUSD(getOptionPrice().currentPrice)}} 
            <span> | <b>{{toPercent(getOptionPrice().currentPrice/getLastStockPrice())}}</b></span>  
        </div>
        <div class="sa-topic-item">
            <b>Option Profit/Loss:</b> <span :style="getOptionPLStyle()"> {{toUSD(getOptionPrice().PL * 100)}}</span> |   
            <span :style="getOptionPLStyle()">{{toPercent(getOptionPrice().percentPL)}}</span>
        </div>
        <!-- <div class="sa-topic-selection" @click="showExpirations = !showExpirations">
            <span>Expiration Options:  {{this.stockdata.bestExpireDate}}</span>
            <div class="sa-caret">
                <span class="fas fa-caret-down"></span>
            </div>

        </div> -->
        
        
        <div class="sa-info-button" @click="showOptionsChart=!showOptionsChart">{{getOptionsButtonLabel()}}</div>
        <daily-chart
            v-if="showOptionsChart"
            v-bind:chartData="companyData"
            :evalDate="optionDate">
        </daily-chart>
        
    </div>
</template>

<script>
import DailyChart from '../components/DailyChartComponent.vue';
// import DropDownComponent from './Utilities/DropDownComponent.vue';
import Globals from '../utils/globals';
import StockChartComponent from '../components/StockChartComponent.vue';
import StockExpirations from '../components/StockExpirationsComponent.vue';
import FinancialInfoComponent from '../components/FinancialDataComponent.vue';
import DropdownButtonComponent from '../components/Utilities/DropdownButtonComponent.vue';
import Constants from '../utils/constants';

export default {
    props: ['stockdata', 'udata'],
    components: {
        DailyChart,
        StockChartComponent,
        StockExpirations,
        FinancialInfoComponent,
        DropdownButtonComponent,
    },
    
    data() {
        return {
            stockData: {name:'test',description:'About',company:{name:''}},
            myData: '',
            company: this.stockdata.company,
            showInfo: true,
            showPercent: true,
            showExpirations: false,
            showStockChart: false,
            showFincInfo: false,
            optionDate: "2022-06-17",
            stockDataOptions: [],
            selectedOptionDate: '2022-06-17',
            companyData: {...this.stockdata},
            utils: Constants,
            stockChartData: {},
            showOptionsChart: false,
            financialInfo: {},
            selectedTimeframe: 6,
        }
    },
    methods: {
        getOptionPrice() {
            let initialPrice = 0;
            let currentPrice = 0
            this.stockdata.options.forEach(option => {
                if (option.expirationDate === this.stockdata.bestExpireDate) {
                    initialPrice = (option.bid + option.ask)/2
                    const lastOption = option.savedOptions[option.savedOptions.length - 1];
                    currentPrice = (lastOption.bid + lastOption.ask)/2;
                }
            })
            return { 
                initialPrice: initialPrice, 
                currentPrice: currentPrice,
                PL: currentPrice - initialPrice,
                percentPL: (currentPrice - initialPrice)/initialPrice}
        },
        getPL(option) {
            return this.getLastPrice(option)-((option.bid + option.ask)/2)
        },
        getPLStyle(option) {
            return { color: this.getPL(option) >=0 ? 'green' : 'red'}
        },
        getStockPLStyle() {
            return  {color: this.getLastStockPrice() > this.stockdata.price ? 'green' : 'red'};
        },
        getOptionPLStyle() {
            return {color: this.getOptionPrice().PL > 0 ? 'green' : 'red'}
        },
        getLastPrice(option) {
            const SavedOptions = option.savedOptions
            return (SavedOptions[SavedOptions.length - 1].bid + SavedOptions[SavedOptions.length - 1].ask)/2
        },
        getLastStockPrice() {
            const lastOption = this.stockdata.options[this.stockdata.options.length - 1];
            return lastOption.savedOptions[lastOption.savedOptions.length - 1].currentPrice
        },
        getExpireDates() {
            this.stockdata.options.forEach(option => {
                this.stockDataOptions.push({name:option.expirationDate})
            })
            console.log('OPTS',this.stockDataOptions)
        },
        toUSD(value) {
            return Globals.toUSD(value)
        },
        toPercent(value) {
            return Globals.toPercent(value)
        },
        setStockChart() {
            this.stock.ticker = this.stockdata.ticker.slice();
            const today = new Date();
            this.stockChartData = {
                stock: this.stock.ticker,
                end: Globals.setDateFormat(today),
                start: Globals.setDateFormat(
                    new Date(today.setDate(today.getDate() - Math.round((356 / 12) * this.historyLength.value)))
                ),
            };
            this.showStockChart = true;
        },
        getChartButtonLabel() {
            return this.showStockChart ? 'HIDE STOCK CHART' : 'SHOW STOCK CHART'
        },
        getFinancialButtonLabel() {
            return this.showFincInfo ? 'HIDE FINANCIAL INFO' : 'SHOW FINANCIAL INFO'
        },
        getExpirationsButtonLabel() {
            return this.showExpirations ? 'HIDE STOCK EXPIRATIONS' : 'SHOW STOCK EXPIRATIONS'
        },
        getOptionsButtonLabel() {
            return this.showOptionsChart ? 'HIDE OPTION PL CHART' : 'SHOW OPTION PL CHART'
        },
        getFinancialInfo() {
            this.$store.dispatch('getNewStockInfo',{ticker: this.stockdata.ticker,link:'get-summary'}).then(response => {
                console.log('COMP FIN INFO', response)
                this.financialInfo = response;
            });
        },
    },
    watch: {
        stockdata() {
            console.log ('SD C', this.stockdata, this.stockdata.company.name)
            this.showInfo = true;
            
        },
        selectedOptionDate() {
            
            this.companyData.bestExpireDate = this.selectedOptionDate.name;
            console.log('DATE CHANGED',this.selectedOptionDate,this.companyData)
        },
        selectedTimeframe() {
            console.log('LENGTH CHANGED', this.selectedTimeframe)
        }
    },
    mounted() {
        console.log('MOUNTED', this.udata,this.stockdata, 'COMPANY', this.stockdata.company)
        this.getExpireDates();
        this.getFinancialInfo();
        // types:  get-statistics, get-recommendations
        this.$store.dispatch('getStatistics',{ticker: this.stockdata.ticker,type:'get-recommendations'});
    }
    
}
</script>

<style lang="scss" scoped>
.sa-page-container {
    margin: 10px;
}
// .sa-topic-item {
//     padding-top: 10px;
// }
.sa-topic-selection {
    padding: 10px;
    border: 1px solid #707078;
    margin: 5px 0;
    font-weight: 600;
}
.sa-option-item {
    height: 25px;
    border-bottom: 1px solid lightgray
}
.sa-option-detail {
    display: inline-block;
    padding: 5px;
}
.sa-option-label-wide {
    display: none;
}
.sa-option-label-sm {
    display: inline-block;
}
.sa-caret {
    float: right;
    padding-right: 20px;
}
// .sa-info-button {
//     padding: 10px 15px;
//     background-color: #238874;
//     color: white;
//     max-width: 300px;
//     text-align: center;
//     margin: 5px 0 0 10px;
//     cursor: pointer;
// }

@media  screen and (min-width: 680px) {
   .sa-option-label-wide {
       display: inline-block;
   } 
   .sa-option-label-sm {
    display: none;
}
}
</style>