export default {

    nodeURL: "https://stockanalyser.com/", //"http://localhost:443/",
  
    setDateFormat(calcDate) {
      const date = new Date(calcDate)
      return `${String(date.getFullYear())}-${this.to2Digits(
        date.getMonth() + 1
      )}-${this.to2Digits(date.getDate())}`;
    },
    to2Digits(number) {
      return String(number).length === 1 ? "0" + String(number) : String(number);
    },
    dateDiffDaysToday(date) {
      const today = new Date().getTime();
      const toDate = new Date(date).getTime();
      const millDiff = toDate - today;
      return Math.floor(millDiff / (1000 * 3600 * 24));
    },
    setDateDMY(day, month, year) {
      return `${year}-${this.to2Digits(month + 1)}-${this.to2Digits(day)}`;
    },
    toUSD(value) {
      if (value) {
        return (
          "$" +
          Number.parseFloat(value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );
      }
      return "$0.00";
    },
    toPercent(value) {
      if (value) {
        return (Number(value) * 100).toFixed(1) + "%";
      } else {
        return "";
      }
    },
  };
  