<template>
  <div class="checkbox-container">
    <label class="container">
      <div class="checkbox-label" :class="labelStyle">{{ checkBoxLabel }}</div>
      <input :type="getType()" value="isSelected" v-model="cbvalue" />
      <span class="checkmark selected" :style="getStyle()"></span>
      <span class="checkmark" :style="setStyle()"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  data: function () {
    return {
      cbvalue: this.cbmodel,
    };
  },
  props: ["checkBoxLabel", "labelStyle", "cbref", "cbmodel", "inactive"],
  watch: {
    cbvalue: function () {
      window.console.log(
        "CB CHANGED",
        `${this.cbref}-${this.cbvalue}`,
        "INACTIVE",
        this.inactive,
        this.cbmodel
      );
      if (!this.inactive) {
        window.console.log("EMIT UPDATE");
        this.$emit("cb-selected", `${this.cbref}-${this.cbvalue}`);
        this.$emit("item-selected", this.cbvalue);
      }
    },
    cbmodel() {
      window.console.log(
        "CHECKBOX MODEL CHANGED",
        this.cbmodel,
        `${this.cbref}-${this.cbvalue}`
      );
      this.cbvalue = this.cbmodel;
    },
  },
  methods: {
    setStyle() {
      if (this.labelStyle === "label-small") {
        return {
          top: "2px",
        };
      }
    },
    getType() {
      if (this.inactive) {
        return "";
      } else {
        return "checkbox";
      }
    },
    getStyle() {
      if (this.inactive && this.cbmodel) {
        return { display: "block" };
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f8fcff;
  border: 1px solid #2196f3;
}

.selected {
  background-color: #2196f3;
  z-index: 1;
  display: none;
}

.container .selected:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #aedbf9;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container {
  display: inline-block;
  vertical-align: top;
}
.checkbox-label {
  padding: 0;
  word-break: normal;
  width: 140px;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
}
.label-small {
  font-size: 14px;
  width: 60px;
  line-height: 12px;
  padding-left: 0;
}
.label-long {
  width: auto;
}
@media screen and (max-width: 430px) {
  .checkbox-label {
    width: 100%;
  }
}
</style>
