import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import PicksPage from '../views/PicksPage.vue'
import SignInPage from '../views/SignInPage.vue';
import RegistrationPage from '../views/RegistrationPage.vue';
import StockInfoPage from '../views/StockInfoPage.vue';
// import CompanyPage from '../views/CompanyPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/signin'
  },
  {
    path: '/home',
    name: 'Home',
    component: PicksPage
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignInPage
  },
  {
    path: '/registration/',
    name: 'Registration',
    component: RegistrationPage
  },
  {
    path: '/company/:id',
    name: 'Company',
    component: () => import( '../views/CompanyPage.vue')
  },
  {
    path: '/stock',
    name: 'Stock',
    component: StockInfoPage
  },
  // {
  //   path: '/company',
  //   name: 'Company',
  //   // component: () => import( '../views/CompanyPage.vue')
  //   component: CompanyPage
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
