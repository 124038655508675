<template>
    <ion-page>
        <main-body-component title="Registration">
            <registration-form @submit-registration="userRegistration"></registration-form>
        </main-body-component>
        
    </ion-page>
</template>

<script>
import MainBodyComponent from '../components/MainBodyComponent.vue'
import RegistrationForm from '../components/RegistrationForm.vue'
import { IonPage } from '@ionic/vue'
export default {
    components: {
        MainBodyComponent,
        RegistrationForm,
        IonPage,
    },
    methods: {
        userRegistration(data) {
            console.log('Registration DATA',data)
            this.$router.replace('/home')
        }
    }
}
</script>