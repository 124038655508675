<template>
    <div>
        <div v-for="(sector,index) in Object.keys(constants.sectors)" :key="index">
            <div>
                <h3>{{sector}}</h3>
            </div>
            <div>
                <stock-details-component :picklist="getList(sector)" :totalValues="getTotalValues(sector)" v-on:stock-selected="stockId=$event"></stock-details-component>
            </div>
           
        </div>
    </div>
</template>

<script>
import Constants from '../utils/constants';
// import jsonata from 'jsonata';
import StockDetailsComponent from './StockDetailsComponent.vue';

export default {
    props: ['stockData'],
    components: {
        StockDetailsComponent,
    },
    data() {
        return {
            constants: Constants,
            stockId: 0,
        }
    },
    methods: {
        getList(sector) {
            const sectorList = this.stockData.filter(stock => stock.company.Sector == sector);
                return sectorList
        },
        getTotalValues(sector) { 
            const stocks = this.getList(sector)
            const totalValues = {totalCost: 0,totalValue:0}          
            stocks.forEach(item => {               
                totalValues.totalCost += Number(item.initialOptionPrice);
                totalValues.totalValue += Number(item.lastOptionPrice);
            })
            totalValues.totalCost = (totalValues.totalCost * 100).toFixed(2);
            totalValues.totalValue = (totalValues.totalValue * 100).toFixed(2);
            return totalValues;
        },
    },
    watch: {
        stockId() {
            console.log('ID Changed', this.stockId)
            this.$emit('stock-selected',this.stockId)
        }
    }
}

</script>

<style lang="scss" scoped>
h3 {
    margin: 5px 15px 0 15px;
}
</style>