<template>
    <ion-page> 
        <main-body-component :title="pageTitle" v-on:show-select="showComponent=$event">
            <div v-if="showLoader">
                <div class="sa-load-back"></div>
                <img src="../assets/loadBarsBlue.gif" class="sa-load-bars" />
            </div>
            <picks-filters-component v-if="showComponent==='Filters'" :filters="filters" v-on:update-filters="this.filters=$event"></picks-filters-component>
            <picks-results-component v-if="showComponent==='Picks'" 
                :picklist="selectList" 
                :totalValues="totalValues" 
                v-on:stock-selected="stockId=$event"></picks-results-component>  
            <add-pick-component v-if="showComponent==='AddPick'" v-on:add-stock="addStock=$event"></add-pick-component>
            <company-info-component :stockdata="stockData" v-if="showComponent==='Info'"></company-info-component>
            <stock-info-component v-if="showComponent==='GetInfo'"></stock-info-component>
            <stock-analysis-component v-if="showComponent==='Analysis'" :stockData="selectList" v-on:stock-selected="stockId=$event"></stock-analysis-component>
        </main-body-component>
    </ion-page>
</template>

<script>
import MainBodyComponent from '../components/MainBodyComponent.vue';
import PicksResultsComponent from '../components/PicksResultsComponent.vue';
import PicksFiltersComponent from '../components/PicksFiltersComponent.vue';
import AddPickComponent from '../components/AddPickComponent.vue';
import CompanyInfoComponent from '../components/CompanyInfoComponent.vue';
import StockInfoComponent from '../components/StockInfoComponent.vue';
import StockAnalysisComponent from '../components/StockAnalysisComponent.vue';

import Globals from '../utils/globals';
import Constants from '../utils/constants';
import store from '../store/index';
import { IonPage } from '@ionic/vue';

export default {
    components: {
        MainBodyComponent,
        PicksResultsComponent,
        PicksFiltersComponent,
        AddPickComponent,
        CompanyInfoComponent,
        StockInfoComponent,
        IonPage,
        StockAnalysisComponent
    },
    data() {
        return {
            store,
            pickList: [],
            selectList: [],
            pickTotals: {
                purchaseStockPrice: 0,
                currentPrice: 0,
                purchaseOptionPrice: 0,
                currentOptionPrice: 0,
                numberPicks: 0,
            },
            filters: {
                optionDate: "2022-06-17",
                purchaseDate: "2022-01-01",
                purchaseBelow: 200,
                daysLeft: 365,
                daysPurchase: 365,
                isMinExpire: true,
                minExpireLen: 160,
                putsOnly: false,
                noPuts: false,
            },           
            totalValues: {},
            menuSelected: 'Picks',
            pageTitle: Constants.OPTIONS,
            updateCt: 0,
            showComponent: '',
            addStock: '',
            stockId: 0,
            showLoader: false,
        }
    },
    methods: {
        getSelectList() {
            this.selectList = this.$store.getters.selectlist;
            if (!this.selectList.length) {this.getPickList()}
        }, 
        getPickList() {
            this.showLoader = true;
            this.$store.dispatch("getPickList").then((response) => {
                if (response.result == "SUCCESS") {
                    this.pickList = response.data.rows;
                    this.updateCt = 0;
                    this.updateAllCompanyData(0)
                    this.pickList.map((item) => {
                        item.date = Globals.setDateFormat(new Date(item.dateentered));
                    });
                    this.filterList();
                    console.log('SEL FILTERED',this.selectList);
                    this.formatList();
                    console.log("SELECT LIST", this.selectList);
                    this.showComponent = 'Picks'
                    this.showLoader = false;
                    
                } else {
                    console.log('PICKLIST NO SUCCESS', response)
                }
            });
        },
        filterList() {
            this.selectList = this.pickList.filter((item) => {
                if (
                    (new Date(item.dateentered) > new Date(this.filters.purchaseDate)) 
                    // && this.getDaysTilExpire(item) < this.filters.daysLeft 
                    // && this.getDaysSinceBought < this.filters.daysPurchase 
                    // && (item.initialOptionPrice < this.filters.purchaseBelow) 
                ) {
                return item;
                }
            });
        },
        filterPutsOnly() {
            this.selectList = [...this.pickList]
            this.selectList = this.pickList.filter(item => {return item.buytype === 'put'})
            this.setTotalValues()
        },
        refilterList() {
            console.log('LIST',this.pickList.length, this.selectList.length)
            this.selectList = this.pickList.filter(item => {return item})
            this.formatList();
            this.selectList = this.selectList.filter((item) => {
                if (
                    (new Date(item.dateentered) > new Date(this.filters.purchaseDate)) 
                    && this.getDaysTilExpire(item) < this.filters.daysLeft 
                    && this.getDaysSinceBought(item) < this.filters.daysPurchase 
                    && (item.initialOptionPrice < this.filters.purchaseBelow) 
                ) {
                return item;
                }
            });
            if (this.filters.noPuts) {
                this.selectList = this.selectList.filter(item => {return item.buytype !== 'put'})
            }
            this.setTotalValues();
        },
        formatList(){
            this.selectList.map(item => {
                item.bestExpireDate = this.getBestExpireDate(item);
                const optionPrices = this.getBestPrevOptionPrice(item);
                item.prevOptionPrice = optionPrices.prev;
                item.lastOptionPrice = optionPrices.last;
                item.initialOptionPrice = optionPrices.initial;
                item.sector = item.company?.Sector ? item.company.Sector : ''
            })
            this.setTotalValues()
        },
        getDaysTilExpire(item) {
            const dateExp =   new Date(item.bestExpireDate);
            const dateNow = new Date();
            const dateDif = dateExp.getTime() - dateNow.getTime()
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.ceil(dateDif/dayLength)
        },
        getDaysSinceBought(item) {
            const dateBought =   new Date(item.date);
            const dateNow = new Date();
            const dateDif = dateNow.getTime() - dateBought.getTime();
            const dayLength = 1000 * 60 * 60 * 24           
            return Math.floor(dateDif/dayLength)
        },
        getExpireByLength(stockData) {
            const options = stockData.options
            const buyDate = new Date(stockData.date);
            const minDate =   buyDate.setDate(buyDate.getDate() + this.filters.minExpireLen);
            for (let i = 0;i<options.length;i++) {
                if (new Date(options[i].expirationDate) > minDate) {
                    return options[i].expirationDate
                }
            }
        },
        getBestExpireDate(stockData) {
            if (this.filters.isMinExpire) {
                return this.getExpireByLength(stockData)
            } else {
                const bestDateExists = stockData.options.find(option => option.expirationDate === this.filters.optionDate)
                if (bestDateExists) {
                    return this.filters.optionDate
                } else {
                    if ( stockData.options.length === 1) {
                        return stockData.options[0].expirationDate;
                    } else {
                        for (let i = 0; i < stockData.options.length; i++) {
                            if (stockData.options[i].expirationDate > this.filters.optionDate) {
                                return stockData.options[i].expirationDate;
                            }
                        }
                    }
                }
            }
        },
        getBestPrevOptionPrice(stockData) {
            const optionData = stockData.options.find(option => option.expirationDate === stockData.bestExpireDate);
            if (optionData?.savedOptions?.length >= 2) {
                const lastPrice = (optionData.savedOptions[optionData.savedOptions.length - 1].bid + optionData.savedOptions[optionData.savedOptions.length - 1].ask)/2;
                const prevPrice = (optionData.savedOptions[optionData.savedOptions.length - 2].bid + optionData.savedOptions[optionData.savedOptions.length - 2].ask)/2;
                return {
                    last: lastPrice,
                    prev: prevPrice > 0 ? prevPrice : (optionData.savedOptions[optionData.savedOptions.length - 3].bid + optionData.savedOptions[optionData.savedOptions.length - 3].ask)/2,
                    initial: (optionData.bid + optionData.ask)/2
                }
            } else {
                if (optionData?.savedOptions?.length === 1) {
                    return {last: (optionData.savedOptions[0].bid + optionData.savedOptions[0].ask)/2, prev: (optionData.savedOptions[0].bid + optionData.savedOptions[0].ask)/2, initial: (optionData.bid + optionData.ask)/2}
                } else {
                    if (optionData) {
                        return {last: (optionData.bid + optionData.ask)/2, prev: (optionData.bid + optionData.ask)/2, initial: (optionData.bid + optionData.ask)/2}
                    } else {
                        return {last: 0, prev: 0, initial: 0}
                    }
                }
                
            }
        },
        setTotalValues() { 
            this.totalValues = {totalCost: 0,totalValue:0}          
            this.selectList.forEach(item => {               
                this.totalValues.totalCost += Number(item.initialOptionPrice);
                this.totalValues.totalValue += Number(item.lastOptionPrice);
            })
            this.totalValues.totalCost = (this.totalValues.totalCost * 100).toFixed(2);
            this.totalValues.totalValue = (this.totalValues.totalValue * 100).toFixed(2);
            this.$store.commit('SET_TOTALVALUES', this.totalValues)
        },
        updateAllCompanyData(num) {
            if (this.pickList[num]) {
            const companyData = this.pickList[num].company
            if (!companyData || (!companyData.note && !companyData.Sector)) {
                console.log('TRY UPDATE',this.pickList[num].ticker)
                this.$store.dispatch('getCompanyInfo',this.pickList[num]).then(response => {
                    console.log('UPDATE COMPANY dis',response)
                    num++
                    if (num < this.pickList.length && (!response?.company || !response.company?.Note)){
                        setTimeout(() => {
                            this.updateAllCompanyData(num) 
                        })                                          
                    }
                })               
            } else {
                num++
                this.updateAllCompanyData(num) 
            }
            }
        }
        
    },
    beforeMount() {
        // this.showLoader = true;
        this.getPickList();
    },
    watch: {
        'filters.purchaseBelow' () {           
            console.log('FILTERS UPDATED', this.filters)            
        },
        filters: {
            handler(newValue) {
                console.log('CHANGED', newValue);
                this.showComponent = 'Picks';
                if (this.filters.putsOnly) {
                    this.filterPutsOnly()
                } else {
                    this.refilterList();
                }               
            },
            deep: true
        },
        menuSelected: {
            handler(newValue) {
                this.showComponent = newValue;
                console.log('MENU',this.menuSelected, newValue,'SHOW', this.showComponent);

                switch(newValue) {
                    case 'Picks':
                        this.pageTitle = Constants.pageTitles.OPTIONS;
                        break;
                    case 'Filters':
                        this.pageTitle = Constants.pageTitles.FILTERS;
                        break;
                    case 'AddPick':
                        this.pageTitle = Constants.pageTitles.ADD;
                        break;
                }
            }
        },
        addStock: {
            handler(newValue) {
                console.log('ADD STOCK', this.addStock, newValue)
                this.showLoader = false;
                this.$store.dispatch('getCompanyInfo',this.addStock).then(() => {
                    this.showLoader = false;
                    this.getPickList();
                });
                
            }
        },
        stockId: {
            handler(value) {
                console.log('STOCK ID', value)
                this.stockData = this.selectList.find(stock => {return stock.id === value})
                this.showComponent = 'Info'
            }
        }
    }
    
}
</script>

<style scoped>
    .sa-pick-result{
        margin-left: 0;
    }
</style>