<template>
    <ion-page>
        <main-body-component :title="'Stock Information'" v-on:show-select="showComponent=$event">
            <div class="sa-stock-block">
                <input
                    type="text"
                    class="sa-button-input"
                    v-model="ticker"
                    placeholder="Ticker"
                    @input="onChange"
                />
                <div class="sa-input-button" @click="getStocks(-1)">GET STOCK</div>
                <Dropdown
                    v-on:item-selected="historyLength = $event"
                    v-bind:selected="historyLength.name"
                    v-bind:optioncontent="historyOptions"
                    :placeholder="historyLength.name"
                >
                </Dropdown>
            </div>
        </main-body-component>
    </ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue'
import Dropdown from '../components/Utilities/DropDownComponent.vue'
import MainBodyComponent from '../components/MainBodyComponent.vue';

export default {
    components: {
        IonPage,
        Dropdown,
        MainBodyComponent
    },
    data() {
        return {
            showComponent: '',
            ticker: '',
            historyOptions: [
                { name: "6 months", value: 6 },
                { name: "1 year", value: 12 },
                { name: "2 years", value: 24 },
                { name: "3 years", value: 36 },
            ],
            historyLength: { name: "6 months", value: 6 },
        }
    },
    methods: {
        getStocks() {

        },
        onChange() {

        }
    }
    
}
</script>

<style lang="scss" scoped>
.sa-stock-block {
  display: inline-block;
  margin-left: 15px;
}
.sa-stock-block {
  display: inline-block;
  margin-left: 15px;
}
.dropdown-component {
  top: 16px;
}
.dropdown-selected-container {
  height: 40px;
}
.sa-button-input {
    height: 24.5px;
    padding: 5px;
    vertical-align: top;
    margin-top: 15px;
    width: 45px;
  }
  .sa-input-button {
    background-color: #0087ff;
    padding: 10px 20px;
    display: inline-block;
    color: white;
    cursor: pointer;
    margin-top: 15px;
    vertical-align: top;
  }
</style>