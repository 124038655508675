<template>
    <div>
        <div class="sa-get-info-row">
              <input ref="saInput" class="sa-inline-input" type="text" placeholder="Stock Ticker" v-model="stockTicker" @input="onChange">
              <div class="sa-inline-btn" :class="{'disabled':!stockTicker.length}" @click="getFinancialInfo" expand="block">Get Stock Info</div>
        </div>
        <div class="sa-page-container" v-if="showCompanyInfo">           
            <financial-info-component :financialInfo="financialInfo"></financial-info-component>            
            <dropdown-button-component 
                v-bind:stockTicker="stockTicker" 
                v-bind:buyDate="buydate" 
                v-on:item-selected="selectedTimeframe=$event.value,showStockChart=$event.show" 
                v-bind:selected="'none'" 
                v-bind:optioncontent="consts.historyOptions"
                :placeholder="consts.historyOptions[0].name">
            </dropdown-button-component>
            <stock-chart-component
                v-if="showStockChart"
                v-bind:ticker="stockTicker"
                v-bind:buydate="buyDate"
                v-bind:stockhistorylength="selectedTimeframe">
            </stock-chart-component>
            <div class="sa-info-button" @click="getStockNews()">{{getShowHide(showReports)}} COMPANY REPORTS</div>
            <div v-if="showReports">
                <div v-for="report in companyReports.finance.result.reports" :key="report.id">
                    <div class="sa-topic-item">{{utils.setDateFormat(report.publishedOn)}} | {{report.title}} 
                        <span><img v-if="showSummary!==report.id" @click="showSummary=report.id" class="sa-chevron-up-down" src="../assets/circle-chevron-down-solid.svg" /></span>
                        <span><img v-if="showSummary===report.id" @click="showSummary=''" class="sa-chevron-up-down" src="../assets/circle-chevron-up-solid.svg" /></span>
                    </div>
                    <div class="sa-topic-item" v-if="showSummary===report.id">{{report.summary}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StockChartComponent from '../components/StockChartComponent.vue';
import FinancialInfoComponent from '../components/FinancialDataComponent.vue';
import DropdownButtonComponent from './Utilities/DropdownButtonComponent.vue';

import Globals from '../utils/globals';
import Constants from '../utils/constants';


export default {
    components: {
        StockChartComponent,
        FinancialInfoComponent,
        DropdownButtonComponent,
    },
    data() {
        return {
            stockTicker: '',
            showStockChart: false,
            showFincInfo: false,
            showCompanyInfo: false,
            showReports: false,
            showSummary: '',
            buyDate: '2000-01-01',
            companyInfo: {},
            financialInfo: {},
            companyReports: {},
            utils: Globals,
            consts: Constants,
            generalInfo: {},
            selectedTimeframe: 6,
        }
    },
    methods: {
        onChange(evt) {
            this.stockTicker = evt.target.value.toUpperCase();
        },
        getStockInfo() {
            this.$store.dispatch('getNewStockInfo',{ticker: this.stockTicker,link:'get-profile'}).then(response => {
                console.log('COMP FIN INFO', response)
                this.companyInfo = response.assetProfile;
                this.generalInfo = response
                this.showCompanyInfo = true;
                //get-summary
            })
            // this.showStockChart = true;
        },
        getFinancialInfo() {
            if (this.stockTicker.length) {
                this.$store.dispatch('getNewStockInfo',{ticker: this.stockTicker,link:'get-summary'}).then(response => {
                    console.log('COMP FIN INFO', response)
                    this.financialInfo = response;
                    this.showFincInfo = true;
                    this.showCompanyInfo = true;
                });
            }
        },
        getStockNews() {
            this.$store.dispatch('getNewStockInfo',{ticker: this.stockTicker,link:'get-insights'}).then(response => {
                console.log('GOT COMPANY NEWS', response); 
                this.companyReports = response;
                this.showReports = true;             
            });
        },
        getChartButtonLabel() {
            return this.showStockChart ? 'HIDE STOCK CHART' : 'SHOW STOCK CHART'
        },
        getShowHide(value) {
            return value ? 'HIDE' : 'SHOW';
        }
    },
    mounted() {
        this.$nextTick(() => {
            console.log('REF', this.$refs)
            this.$refs['saInput'].focus()
        })
    }
    
}
</script>

<style lang="scss" scoped>
.sa-chevron-up-down  {
    height: 20px;
    position: relative;
    top: 5px;
    left: 5px;
}


.disabled {
    background-color: lightgrey;
    cursor: default;
}
</style>