<template>
    <div class="sa-chart-master-container" id="stock-container">
      <!-- <stock-info-box :itemInfo="stockInfoDay">STOCK INFO BOX</stock-info-box> -->
      <div
        class="sa-chart-title"
        v-if="stock.tradingInfo && stock.tradingInfo.symbol"
      >
        {{ stock.tradingInfo.symbol }} : {{ stock.tradingInfo.description }}
      </div>
      <div
        id="sa-chart-container"
        :style="getGraphWidth"
        v-if="showItems.graph"
      >
        <div
          class="graph-line"
          v-for="(line, index) in graphLines"
          :key="`li-${index}`"
          :style="setInfoLine(line)"
        >
          <div class="sa-line-label">{{ line.name }}</div>
          <div class="sa-line-label">{{ line.value }}</div>
        </div>
        <div
          v-for="(day, index) in stockData"
          :key="index"
          @click="showStockData(day, index)"
        >
          <div class="sa-plot-line" :style="setPlotLinePosition(index,day)" />
          <div class="sa-high-low-line" :style="setLine(day, index)" />
          <div class="sa-candlestick" :style="setCandle(day, index)" />
          <!-- <div class="sa-graph-month" :style="setPlotPosition(index)" >{{setPlotMonth(index)}}</div> -->
          <div class="sa-graph-day" :style="setPlotPosition(index)">
            {{ setPlotDay(index) }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Constants from '../utils/constants';
import Globals from '../utils/globals';

export default {
    props: ['ticker','buydate','stockhistorylength'],
    components: {},
    data() {
        return {
            stock: {},
            priceInfo: {
                max: 145,
                min: 128,
                dif: 27,
            },
            graphInfo: {
                height: 600,
                width: 800,
                heightFactor: 20,
                widthMove: 10,
            },
            graphWidth: "0",
            graphLines: [],
            showItems: {
                graph: false,
                stocklist: false,
                optionlist: false,
                comparelist: false,
                flatlist: false,
            },
            stockUpList: [],
            listType: "Stocks",
            showLoader: false,
            
            monthNum: 0,
            selected: "",
            stockInfoDay: {},
            stockData: [],
        }
    },
    methods: {
        getBuyDate() {
          const dateBought = new Date(this.buydate)
          var dayOfWeek = dateBought.getDay();
          if (dayOfWeek === 6) {
            return Globals.setDateFormat(new Date(dateBought.setDate(dateBought.getDate() + 2)))
          }
          if (dayOfWeek  === 0) {
            return Globals.setDateFormat(new Date(dateBought.setDate(dateBought.getDate() + 2)));
          }
          return this.buydate;
        },
        getStockInfo() {
            console.log("GET STOCKS INFO FOR PLOTTING", this.ticker, this.buydate,'length', this.stockhistorylength);
            this.stock.name = this.ticker.slice();
            const today = new Date();
            this.listType = "Number found " + this.stockUpList.length;
            this.graphLines = [];
            this.setShow("graph");
            this.getStatistics();
            const data = {
                stock: this.stock.name,
                end: Globals.setDateFormat(today),
                start: Globals.setDateFormat(
                    new Date(
                        today.setDate(
                        today.getDate() -
                            Math.round((356 / 12) * this.stockhistorylength)
                        )
                    )
                ),
            };
            console.log('GET STOCK DATA', data)
            this.$store.dispatch("getStockInfo", data).then((response) => {
                console.log(
                "GOT STOCK INFO",
                this.stock.name,
                response,
                response.history
                );

                let maxPrice = 0;
                let minPrice = 0;
                let currentPrice = 0;
                if (response.history) {
                    const days = response.history.day;
                    days.forEach((day, index) => {
                        maxPrice = day.high > maxPrice ? day.high : maxPrice;
                        minPrice = day.low < minPrice || minPrice == 0 ? day.low : minPrice;
                        if (index == days.length - 1) {
                        currentPrice = day.close;
                        }
                    });
                    const priceDif = maxPrice - minPrice;
                    const percentgain = (priceDif / minPrice) * 100;
                    this.priceInfo.maxPrice = maxPrice;
                    this.priceInfo.minPrice = minPrice;
                    this.priceInfo.priceDif = priceDif;
                    this.priceInfo.percentgain = percentgain;

                    this.graphLines.push({ name: "Max", value: maxPrice });
                    this.graphLines.push({ name: "Min", value: minPrice });
                    this.graphLines.push({
                        name: "Avg",
                        value: ((maxPrice + minPrice) / 2).toFixed(2),
                    });
                    this.graphLines.push({
                        name: "Current",
                        value: currentPrice,
                    });
                    this.graphWidth = (response.history.day.length + 1) * 10 + 60 + "px";
                    this.plotGraph();
                    this.stockData = response.history.day;
                }
            });
            this.scrollLeft();
        },
        setShow(item) {
            Object.keys(this.showItems).forEach((key) => {
                this.showItems[key] = key == item ? true : false;
            });
        },
        getStatistics() {
            return new Promise((resolve) => {
                const data = {
                stock: this.ticker,
                };
                console.log('tic', data)
                this.$store.dispatch("getStockStatistics", data).then((response) => {
                console.log("STOCK statostocs", response);
                this.stock.tradingInfo = response;
                resolve(response);
                });
            });
        },
        scrollLeft() {
            setTimeout(() => {
                document.getElementById("stock-container").scrollLeft = 500;
            });
        },
        plotGraph() {
            console.log("PLOT GRAPH");
            this.graphInfo.heightFactor =
                (this.graphInfo.height - 160) / this.priceInfo.priceDif;
            this.scrollLeft();
        },
        setInfoLine(line) { 
          const bottomValue =
              (line.value - this.priceInfo.minPrice) * this.graphInfo.heightFactor +
              80 +
              "px";
          return { bottom: bottomValue };
        },
        setLine(day, index) {
          return {
              left: this.graphInfo.widthMove * (index + 1) + "px",
              bottom:
              (day.low - this.priceInfo.minPrice) * this.graphInfo.heightFactor +
              80 +
              "px",
              height: (day.high - day.low) * this.graphInfo.heightFactor + "px",
          };
        },
        setPlotPosition(index) {
            return { left: this.graphInfo.widthMove * (index + 1) - 3 + "px" };
        },
        setPlotLinePosition(index,day) {
          if (day.date == this.getBuyDate()) {
            return { 
              left: this.graphInfo.widthMove * (index + 1) + "px",
              width: '3px',
              backgroundColor: 'orange'
             };
          }
          return { left: this.graphInfo.widthMove * (index + 1) + "px" };
        },
        setPlotDay(index) {
            const dayArray = this.stockData[index].date.split("-");
            let display = "";
            if (index === 0) {
                display +=
                dayArray[0].substr(2) +
                Constants.months[Number(dayArray[1] - 1)] +
                " ";
            }
            if (
                index > 0 &&
                this.stockData[index - 1].date.split("-")[0] !== dayArray[0]
            ) {
                display += dayArray[0].substr(2);
            }
            if (
                index > 0 &&
                this.stockData[index - 1].date.split("-")[1] !== dayArray[1]
            ) {
                display += Constants.months[Number(dayArray[1] - 1)] + " ";
            }
            display += Number(dayArray[2]);
            return display;
        },
        setPlotMonth(index) {
            const dayArray = this.stockData[index].date.split("-");
            console.log("DAY", index);
            if (this.monthNum !== Number(dayArray[1])) {
                this.monthNum = Number(dayArray[1]);
                return Constants.months[Number(dayArray[1] - 1)];
            }
        },
        setCandle(day, index) {
            const bottom = day.open < day.close ? day.open : day.close;
            const top =
                day.open < day.close ? day.close - day.open : day.open - day.close;
            const color = day.open < day.close ? "green" : "red";
            const startBottom =
                (bottom - this.priceInfo.minPrice) * this.graphInfo.heightFactor +
                80 +
                "px";
            return {
                left: this.graphInfo.widthMove * (index + 1) - 3 + "px",
                bottom: startBottom,
                height: top * this.graphInfo.heightFactor + "px",
                "background-color": color,
            };
        },
        showStockData(day, index) {
            this.stockInfoDay = day;
            console.log(
                "STOCK DATA",
                day.close,
                "high",
                day.high,
                "low",
                day.low,
                "open",
                day.open,
                "date",
                day.date,
                "index",
                index
            );
        },
        setChartWidth() {
            document.getElementById("stock-container").width = window.innerWidth;
        },
    },
    computed: {
        getGraphWidth() {
        return { width: this.graphWidth };
        },
    },
    mounted() {
        this.getStockInfo();
    },
    watch: {
      stockhistorylength() {
        this.getStockInfo();
      }
    }
}
</script>

<style lang="scss" scoped>
.sa-analyse-container {
  padding: 15px;
  border: 1px solid black;
}
.sa-chart-master-container {
  overflow-x: auto;
  border: 2px solid;
  margin: 15px;
}
.sa-chart-title {
  position: relative;
  top: 10px;
  text-align: right;
  padding-right: 15px;
}
.sa-high-low-line {
  position: absolute;
  width: 1px;
  display: inline-block;
  background-color: black;
}
.sa-candlestick {
  position: absolute;
  display: inline-block;
  width: 7px;
}
.sa-graph-day {
  position: absolute;
  width: 12px;
  display: block;
  bottom: 3px;
  font-size: 9px;
  text-orientation: upright;
  writing-mode: vertical-lr;
  line-height: 7px;
}
.sa-graph-month {
  position: absolute;
  width: 12px;
  display: block;
  bottom: 15px;
  font-size: 9px;
  text-orientation: upright;
  writing-mode: vertical-lr;
  line-height: 7px;
}

#sa-chart-container {
  height: 600px;
  background-color: whitesmoke;
  border: 1px solid gray;
  position: relative;
  margin: 30px;
}
.graph-line {
  height: 15px;
  border-bottom: 1px solid #a3c0cc;
  width: 100%;
  position: absolute;
  text-align: right;
}
.sa-line-label {
  font-size: 10px;
  padding-right: 10px;
}
.sa-plot-line {
  width: 1px;
  height: 97%;
  position: absolute;
  bottom: 20px;
  background-color: rgb(209, 214, 216);
}
.sa-stock-item {
  padding: 5px;
  cursor: pointer;
}
.sa-stock-list-title {
  background-color: #adc6e6;
  padding: 8px;
  font-weight: 600;
}
.sa-stock-block {
  display: inline-block;
  margin-left: 15px;
}
.dropdown-component {
  top: 16px;
}
.dropdown-selected-container {
  height: 40px;
}
</style>