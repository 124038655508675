<template>
    <ion-page>
        <ion-header> 
            <div class="sa-header">
                <div class="sa-logo-container">
                    <img class="sa-logo" src="../assets/StockAnaly$e3.png">               
                </div>
                
                    <img class="sa-back-icon" @click="goBack" src="../assets/circle-arrow-left-solid.svg" alt="">
                    <img class="sa-menu-icon" @click="showMenu=!showMenu" src="../assets/bars-solid.svg" alt="">
                

                
                <div class="sa-menu-bar">
                </div>
                    <div class="sa-main-menu" v-if="showMenu">                        
                        <div class="sa-menu-item" @click="showSelect('Picks')">Picks</div>
                        <div class="sa-menu-item" @click="showSelect('Filters')">Filters</div>
                        <div class="sa-menu-item" @click="showSelect('AddPick')">Add Stock</div>
                        <div class="sa-menu-item" @click="showSelect('GetInfo')">Stock Info</div>
                        <div class="sa-menu-item" @click="showSelect('Analysis')">Analysis</div>                        
                    </div>
            </div>           
        </ion-header>
        <ion-content>
            <slot/>
        </ion-content>     
    </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage } from '@ionic/vue';
// import MenuComponent from '../components/MenuComponent.vue'
export default {
    props: ['title'],
    components: {
        IonContent, 
        IonHeader, 
        IonPage, 
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        showSelect(type) {
            console.log('SEL',type)
            this.showMenu = false;
            this.$emit('show-select',type)
        },
        goBack() {
            console.log('BACK')
            this.showSelect('Picks')
        }
    }
}
</script>

<style>

/* ion-header {
    background-color: orange; 
} */
.sa-header {
    width: 100%;
    background-color: #fff;
    position: relative;
    border-bottom: 2px solid lightgray;
}
.sa-logo {
    /* height: 75px; */
    width: 150px;
    padding: 10px;
    padding-bottom: 0;
}
.sa-menu-bar {
    background-color: #4d7c7c;
    height: 5px;
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
    background-color: #4d7c7c;
    color:#fff;
    line-height: 30px;
}
.sa-link-btn {   
    padding-left: 10px;
    display: inline-block;
    cursor: pointer;
}
.sa-link-menu-btn {
    display: inline-block;
    position: relative;
    right: 20px;
    cursor: pointer;
}
.sa-menu-btn {
    float: right;
    --background: none;
    color: orange;
}
.sa-main-menu {
    position: absolute;
    top: 70;
    right: 5px;
    background-color: white;
    width: 150px;
    border: 1px solid #445766;
    z-index: 10;
}
.sa-menu-item {
    padding: 10px;
    font-size: 14px;
    color: #445766;
    border-bottom: 1px solid lightgray;
}
.sa-header-title {
    display: inline-block;
    font-weight: 600;
}
.sa-logo-container {
    width: 100%;
    text-align: center;
}
.sa-back-icon {
    height: 25px;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}
.sa-menu-icon {
    height: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

    
</style>