<template>
    <form @submit.prevent="submitForm">
        <ion-list>
            <ion-item >
                <div class="sa-page-title">REGISTRATION FORM</div>
            </ion-item>
            <ion-item>
                <div class="sa-page-desc">Please enter your email, mobile phone number and a password to register for a new account.
                </div>
            </ion-item>
            <ion-item>
                <ion-label position="fixed" >Email</ion-label>
                <ion-input type="email" required placeholder="Enter Email" v-model="email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="fixed" >Phone</ion-label>
                <ion-input type="tel" required placeholder="Enter Mobile Phone" v-model="phone"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="fixed">Password</ion-label>
                <ion-input type="password" required placeholder="Password" v-model="password"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="fixed">ReEnter Password</ion-label>
                <ion-input type="password" required placeholder="Password" v-model="passwordMatch"></ion-input>
            </ion-item>
            <div class="sa-form-entry">
                <ion-button expand="block" color="medium" type="submit" >Submit</ion-button>
            </div>
        </ion-list>
        
    </form>
    
</template>

<script>
import { IonInput, IonLabel, IonItem, IonButton, IonList } from '@ionic/vue'
export default {
    emits: ['submit-login'],
    components: {
        IonInput,
        IonLabel,
        IonItem,
        IonButton,
        IonList
    },
    methods: {
        submitForm() {
            console.log('submit',this.email,this.password);
            const loginData = {email:this.email,password:this.password};
            this.$emit('submit-login',loginData);
            // this.$store.dispatch('login',).then(response => {
            //     console.log('response',response);
            // })
            
        }
    },
    data() {
        return {
            email: '',
            password: '',
        }
    }
    
}
</script>

<style lang="scss" scoped>
ion-list {
    max-width: 400px;
    margin: auto;
}
.sa-page-title {
    font-weight: 600;
    font-size: 18px;
    padding: 5px;
    text-align: center;
    width: 100%;
    color: #355a67;
}
.sa-page-desc {
    text-align: center;
    padding: 5px;
}
.sa-form-entry {
    padding: 10px;
    margin-left: 40px;
}
</style>