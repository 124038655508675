<template>
  <div>
      <div class="sa-chart-ticker">
        <div class="sa-chart-header-title">{{chartData.ticker}} PL CHART</div>
        <div class="sa-chart-header-info">
          <span class="sa-chart-info-label">Bought:</span><span class="sa-chart-info">{{ optionInfo.initialDate }}</span>
          <span class="sa-chart-info-label">Expires:</span><span class="sa-chart-info"> {{ optionInfo.expireDate }}</span>
          <span class="sa-chart-info-label">Days Left:</span><span class="sa-chart-info"> {{ optionInfo.daysLeft }}</span>
          <span class="sa-chart-info-label">Initial:</span><span class="sa-chart-info"> {{ toUSD(optionInfo.initialStock) }}</span>
          <span class="sa-chart-info-label">Current:</span><span class="sa-chart-info"> {{ toUSD(optionInfo.currentStock) }}</span>
          <span class="sa-chart-info-label">Strike:</span><span class="sa-chart-info"> {{ optionInfo.strike }}</span>
          <span class="sa-chart-info-label">%Full:</span><span class="sa-chart-info"> {{ toPercent(optionInfo.initialOption/optionInfo.initialStock) }}</span>
          <span class="sa-chart-info-label">Initial Option:</span><span class="sa-chart-info"> {{ toUSD(optionInfo.initialOption) }}</span>
          <span class="sa-chart-info-label">Current:</span><span class="sa-chart-info"> {{ toUSD(optionInfo.currentOption) }}</span>
          <span class="sa-chart-info-label">PL:</span><span class="sa-chart-info" :style="getPLStyle(optionInfo.PL)"> {{ toUSD(optionInfo.PL) }}</span>
          <span class="sa-chart-info-label">PL%:</span><span class="sa-chart-info" :style="getPLStyle(optionInfo.PL)"> {{ toPercent((optionInfo.currentOption - optionInfo.initialOption)/optionInfo.initialOption) }}</span>
          <div class="sa-chart-info-selections">
            <span class="sa-chart-info">  
              <CheckBox
              v-bind:checkBoxLabel="'Show Percent'"
              v-on:item-selected="showPercent = $event"
            />
            </span>
            <span class="sa-chart-info">  
              <CheckBox
              v-bind:checkBoxLabel="'Expand Values'"
              v-on:item-selected="expandValues = $event"
            />
            </span>
          </div>
        </div>
      </div>
    <div class="sa-chart-container">
      
      <div class="sa-chart-line"></div>
      <!-- <div class="sa-chart-values-container"> -->
        <div class="sa-chart-values-container" :style="getContainerWidth()">
            <div
            class="sa-chart-values"
            v-for="(item, index) in selectedOption.savedOptions"
            :key="index"
            @click="showData(item)"
            >
            <div class="sa-chart-value" :style="getStyle(item)"></div>
            </div>
        </div>
      <!-- </div> -->
      <div class="sa-info-box" v-if="showDataBox" @click="showDataBox = false">
        <div class="sa-info-item">Date: {{ itemInfo.date }}</div>
        <div class="sa-info-item">
          Price: {{ toUSD(itemInfo.currentPrice) }}
        </div>
        <div class="sa-info-item">
          Cost: {{ toUSD(itemInfo.initialPrice * 100) }}
        </div>
        <div class="sa-info-item">
          Value: {{ toUSD(itemInfo.currentPrice * 100) }}
        </div>
        <div class="sa-info-item" :style="getPLStyle(itemInfo.currentProfit)">
          Profit: {{ toUSD(itemInfo.currentProfit) }}
        </div>
        <div class="sa-info-item" :style="getPLStyle(itemInfo.currentPercentProfit)">
          PL: {{ toPercent(itemInfo.currentPercentProfit) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from '../components/Utilities/CheckBox.vue';
import Globals from '../utils/globals';

export default {
  components: {
      CheckBox
  },
  data() {
    return {
      selectedOption: {},
      baseNumber: 75,
      heightNum: 0.5,
      itemInfo: {},
      showDataBox: false,
      showPercent: false,
      expandValues: false,
      optionInfo: {},
      chartOptionDates: [],
    };
  },
  props: ["chartData", "evalDate"],
  methods: {
    toUSD(value) {
      return Globals.toUSD(value);
    },
    toPercent(value) {
      return Globals.toPercent(value);
    },
    getPLStyle(value) {
      const returnColor = value >= 0 ? 'green' : 'red';
      return {
        color: returnColor
      }
    },
    getDaysTilExpire(item) {      
        const dateExp =   new Date(item.bestExpireDate);
        console.log('EXP',item, item.bestExpireDate, dateExp)
        const dateNow = new Date();
        const dateDif = dateExp.getTime() - dateNow.getTime()
        const dayLength = 1000 * 60 * 60 * 24           
        return Math.ceil(dateDif/dayLength)
    },
    getSelectedOption() {
        console.log('O',this.chartData.bestExpireDate, this.chartData)
      this.chartData.options.forEach((option) => {
        if (option.expirationDate == this.chartData.bestExpireDate) {
          this.selectedOption = option;
          console.log("SELECTED option", this.selectedOption);
          const savedOptions = this.selectedOption.savedOptions
          const lastNum = savedOptions.length - 1;
          this.optionInfo = {
            strike: option.strike,
            currentStock: savedOptions[lastNum].currentPrice,
            initialStock: savedOptions[lastNum].price,
            currentOption: (savedOptions[lastNum].bid + savedOptions[lastNum].ask)/2,
            initialOption: (option.bid + option.ask)/2,
            initialDate: this.chartData.date,
            expireDate: option.expirationDate,
            PL: (((savedOptions[lastNum].bid + savedOptions[lastNum].ask)/2) - ((option.bid + option.ask)/2)) * 100,
            daysLeft: this.getDaysTilExpire(this.chartData),
          }
        }
      });
    },
    getStyle(item) {
      const itemInfo = this.calculateProfit(item);
      return {
        top: (itemInfo.displayTop) + "px",
        height: (itemInfo.displayHeight) + "px",
        backgroundColor: itemInfo.currentProfit > 0 ? 'green' : 'red'
      };
    },
    getContainerWidth() {
        const setWidth = (this.selectedOption.savedOptions?.length * 12) + 'px';
        console.log('SET W',setWidth, this.selectedOption.savedOptions?.length)
        return {width: setWidth}
    },
    calculateProfit(item) {
      const currentPrice = (item.ask + item.bid) / 2;
      const initialPrice =
        (this.selectedOption.ask + this.selectedOption.bid) / 2;
        
      const currentProfit = (currentPrice - initialPrice) * 100;
      const currentPercentProfit = (currentPrice - initialPrice)/initialPrice;
      let displayHeight = 0;
      if (this.showPercent) {
        displayHeight = Math.abs(currentPercentProfit) * this.baseNumber * 2;
      } else {
        displayHeight = Math.abs(currentProfit / 40);
      }
      displayHeight = displayHeight * this.heightNum;
      let displayTop = this.baseNumber;
      if (currentProfit > 0) {
        displayTop = this.baseNumber - displayHeight;
      }
      return {
        displayHeight: displayHeight,
        displayTop: displayTop,
        initialPrice: initialPrice,
        currentPrice: currentPrice,
        currentProfit: currentProfit,
        currentPercentProfit: currentPercentProfit,
      };
    },
    showData(item) {
      console.log("SHOW DATA", item, this.calculateProfit(item));
      this.itemInfo = this.calculateProfit(item);
      this.itemInfo.date = item.date;
      this.showDataBox = true;
      setTimeout(() => {
        this.showDataBox = false;
      }, 5000);
    },
  },
  mounted() {
    this.getSelectedOption();
  },
  watch: {
    expandValues() {     
        this.heightNum = this.expandValues ? 1 : 0.5;
    },
    chartData: {
            handler(newValue) {
              console.log('CD CHG', this.chartData, newValue)
              this.getSelectedOption();
            },
            deep: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.sa-info-box {
  padding: 5px;
  background-color: white;
  border: 1px solid black;
  position: absolute;
  font-size: 12px;
  width: 130px;
  bottom: -120px;
  left: 500px;

}
.sa-chart-ticker {
    margin: 10px;
    margin-bottom: -10px;
    background-color: #f3f9ff;
    padding: 10px;
    padding-bottom: 0;
    font-weight: 600;
    border: 1px solid black;
}
.sa-chart-line {
  width: 100%;
  height: 1px;
  background-color: gray;
  position: relative;
  top: 75px;
}
.sa-chart-value {
  position: relative;
  width: 10px;
  background-color: lightslategrey;
  margin-right: 2px;
  display: inline-block;
  vertical-align: top;
}
.sa-chart-container {
  height: 150px;
  margin: 10px;
  border: 1px solid black;
  text-align: left;
  padding: 0 20px;
  overflow-x: auto;
}
.sa-chart-values {
  display: inline-block;
  position: relative;
  top: 0;
  vertical-align: top;
}
.sa-chart-info-label {
  padding-left: 10px;
  font-weight: 400;
  &:first-child {
    padding-left: 0;
  }
}
.sa-chart-info {
  padding-left: 2px;
  font-weight: 600;
}
.sa-chart-header-title {
  background-color: #78a7a5;
  color: white;
  padding: 5px;
  text-align: center;
  margin-bottom: 5px;
}
.sa-chart-header-info {
  line-height: 24px;
}
.sa-chart-info-selections {
  text-align: center;
  padding-top: 5px;
}
</style>
