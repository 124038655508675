<template>
    <div>
        <div class="sa-topic-item"><b>Name:</b> {{financialInfo.price.longName}}</div>
        <!-- <div class="sa-topic-item"><b>Ticker:</b> {{stockTicker}}</div> -->
        <div class="sa-topic-item"><b>Description:</b> {{financialInfo.summaryProfile?.longBusinessSummary}}</div>
        <div class="sa-topic-item"><b>Industry:</b> {{financialInfo.summaryProfile?.industry}}</div>
        <div class="sa-topic-item"><b>Sector:</b> {{financialInfo.summaryProfile?.sector}}</div>
        <div class="sa-topic-item"><b>Employees:</b> {{financialInfo.summaryProfile?.fullTimeEmployees}}</div>
        <div class="sa-topic-item"><b>Price:</b> {{utils.toUSD(financialInfo.price?.regularMarketPrice.raw)}}</div>
        <div class="sa-topic-item"><b>50 Day Avg:</b> {{utils.toUSD(financialInfo.summaryDetail?.fiftyDayAverage.raw)}}</div>
        <div class="sa-topic-item"><b>52 Week High:</b> {{utils.toUSD(financialInfo.summaryDetail?.fiftyTwoWeekHigh.raw)}}</div>
        <div class="sa-topic-item"><b>52 Week Low:</b> {{utils.toUSD(financialInfo.summaryDetail?.fiftyTwoWeekLow.raw)}}</div>
        <div class="sa-topic-item"><b>Market Cap:</b> {{financialInfo.price?.marketCap.fmt}}</div>
        <div class="sa-topic-item"><b>Avg Vol:</b> {{financialInfo.price?.averageDailyVolume3Month.fmt}}</div>
        <div class="sa-topic-item"><b>Gross Profits:</b> {{financialInfo.financialData?.grossProfits.fmt}}</div>
        <div class="sa-topic-item"><b>Gross Margins:</b> {{financialInfo.financialData?.grossMargins.fmt}}</div>
        <div class="sa-topic-item"><b>Operating Cashflow:</b> {{financialInfo.financialData?.operatingCashflow.fmt}}</div>
        <div class="sa-topic-item"><b>Operating Margins:</b> {{financialInfo.financialData?.operatingMargins.fmt}}</div>
        <div class="sa-topic-item"><b>Total Cash:</b> {{financialInfo.financialData?.totalCash.fmt}}</div>
        <div class="sa-topic-item"><b>Total Debt:</b> {{financialInfo.financialData?.totalDebt.fmt}}</div>
        <div class="sa-topic-item"><b>Total Revenue:</b> {{financialInfo.financialData?.totalRevenue.fmt}}</div>
        <div class="sa-topic-item"><b>Revenue Growth:</b> {{financialInfo.financialData?.revenueGrowth.fmt}}</div>
        <div class="sa-topic-item"><b>Revenue Per Share:</b> {{utils.toUSD(financialInfo.financialData?.revenuePerShare.raw)}}</div>     
    </div>
</template>

<script>
import Globals from '../utils/globals';

export default {
    props: ['financialInfo'],
    data() {
        return {
            utils:Globals
        }
    }
}
</script>

<style lang="scss" scoped>

</style>