<template>
    <form @submit.prevent="submitForm">
        <ion-list>
            <ion-item class="sa-signin-inst">Please enter your email and password to log-in to your account.</ion-item>
            <ion-item >New User? <span class="sa-reg-link"><a href="/registration" >Click Here to Register</a></span></ion-item>
            <ion-item>
                <ion-label position="fixed" >Email</ion-label>
                <ion-input type="email" required placeholder="Enter Email" v-model="email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="fixed">Password</ion-label>
                <ion-input type="password" required placeholder="Password" v-model="password"></ion-input>
            </ion-item>
            <ion-button expand="block" color="medium" type="submit" >Submit</ion-button>
        </ion-list> 
    </form>
    
</template>

<script>
import { IonInput, IonLabel, IonItem, IonButton, IonList } from '@ionic/vue'
export default {
    emits: ['submit-login','register-user'],
    components: {
        IonInput,
        IonLabel,
        IonItem,
        IonButton,
        IonList
    },
    methods: {
        submitForm() {
            console.log('submit',this.email,this.password);
            const loginData = {email:this.email,password:this.password};
            this.$emit('submit-login',loginData);
            // this.$store.dispatch('login',).then(response => {
            //     console.log('response',response);
            // })
            
        },
        register() {
            console.log('register')
            this.$emit('register-user');
        }
    },
    data() {
        return {
            email: '',
            password: '',
        }
    }
    
}
</script>

<style lang="scss" scoped>
ion-list {
    max-width: 400px;
    margin: auto;
}
ion-button {
    margin: auto;
    margin-top: 20px;
}
.sa-reg-link {
    margin-left: 15px;
}
.sa-signin-inst {
    font-weight: 600;
    text-align: center;
    padding-bottom: 15px;
}
</style>