export default {
    polygonToken: "npFk_ZO9NqlsRSEdVTVfxV7DXlG2sx7s",
    tradierToken: "DKe97e74iUyU9g0J1DlG3AMJ0rHw",
    tradierURL: "https://sandbox.tradier.com/v1/",
    polygonURL: "https://api.polygon.io/v2/",
    rapidAPIHost: 'yh-finance.p.rapidapi.com',
    rapidAPIToken: 'db058ed9c2msh48d2d36f4dd3359p1cea14jsnd9c6dcf1ed26',
    rapidAPIURL: 'https://yh-finance.p.rapidapi.com/stock/v2/',
    // nodeURL: 'https://simplifyexpress.com/',
    // nodeURL: "http://localhost:443/",
    nodeURL:
      // "http://analyzeserverapp-env-1.eba-u7em6t2p.us-east-2.elasticbeanstalk.com/",
      "https://stockanalyse.com/",
      // "http://localhost:443/",
    months: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    pageTitles: {
      OPTIONS: 'Option Tracking',
      FILTERS: 'Filter Options',
      STATUS: 'Option Status',
      CHARTS: 'Option Charts'
    },
    sectors: {
      'MANUFACTURING': 'M',
      'LIFE SCIENCES': 'LS',
      'FINANCE': 'F',
      'ENERGY & TRANSPORTATION': 'ET',
      'TECHNOLOGY': 'T',
      'TRADE & SERVICES': 'TS',
      'REAL ESTATE & CONSTRUCTION': 'RC',
      'CONSUMER CYCLICAL': 'CC',
    },
    historyOptions: [
      { name: "6 months", value: 6 },
      { name: "1 year", value: 12 },
      { name: "2 years", value: 24 },
      { name: "3 years", value: 36 },
    ],
    historyLength: { name: "6 months", value: 6 },
  };
  